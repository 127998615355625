import React from "react";
import { CheckMark } from "../../../icons";

const WhyJoinSix = () => {
  return (
    <React.Fragment>
      <div className="events6ix-layout-page-middle event-page-whysix-join">
        <div className="event-page-whysix-join-row">
          <div className="event-page-whysix-join-row-item">
            <div className="event-page-whysix-join-row-item-heading">
              Why join 6ix?
            </div>
            <div className="event-page-whysix-join-row-item-text">
              Pronounced “six”
            </div>
          </div>

          <div className="event-page-whysix-join-row-item">
            <div className="event-page-whysix-join-row-item-title">
              To help you invest better...
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark /> Discover investment opportunities
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Engage with the community
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Meet without leaving the app
            </div>
          </div>

          <div className="event-page-whysix-join-row-item">
            <div className="event-page-whysix-join-row-item-title">
              To help you attract capital...
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Reach qualified investors
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Engage investors directly
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Convert investors into shareholders
            </div>
          </div>

          <div className="event-page-whysix-join-row-item">
            <div className="event-page-whysix-join-row-item-title">
              To help you share expertise...
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Amplify your reach
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Network with others
            </div>
            <div className="event-page-whysix-join-row-item-list">
              <CheckMark />
              Monetize your expertise
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhyJoinSix;
