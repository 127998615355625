import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalValue } from "../../../GlobalContext";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import AccountAbout from "./AccountAbout";
import AccountBanner from "./AccountBanner";
import AccountCalendar from "./AccountCalendar";
import AccountConnect from "./AccountConnect";
import AccountLeaveTeam from "./AccountLeaveTeam";
import AccountLogo from "./AccountLogo";
import AccountName from "./AccountName";
import AccountResetTeam from "./AccountResetTeam";
import AccountSlug from "./AccountSlug";
import AccountStock from "./AccountStock";
import AccountTeamID from "./AccountTeamID";
import AccountWidget from "./AccountWidget";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";

const AccountGeneralSettings = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { setIsUserReinitialize } = useGlobalValue();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const [orgLogoPreview, setOrgLogoPreview] = useState("");
  const [orgBannerPreview, setOrgBannerPreview] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgSlug, setOrgSlug] = useState("");
  const [orgCalLink, setOrgCalLink] = useState("");
  const [orgAbout, setOrgAbout] = useState("");
  const [orgLogo, setOrgLogo] = useState("");
  const [orgBanner, setOrgBanner] = useState("");
  const [orgLogoColor, setOrgLogoColor] = useState("#131313");
  const [teamId, setTeamId] = useState("");
  const [loading, setLoading] = useState(true);
  const [cropOrgLogo, setCropOrgLogo] = useState("");
  const [cropOrgBanner, setCropOrgBanner] = useState("");

  const [selectLogo, setSelectLogo] = useState(false);
  const [selectBanner, setSelectBanner] = useState(false);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [settingData, setSettingData] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [orgPublic, setOrgPublic] = useState("");
  const [orgTicker, setOrgTicker] = useState("");
  const [orgStock, setOrgStock] = useState("");
  const [widget, setWidget] = useState("");

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);
      setDataFetched(false);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    ACCOUNT_SERVICES.getSetting({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setSettingData(data.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
          navigate("/dashboard");
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });

    return () => null;
  }, [userData, navigate, LOGOUT_USER_SESSION, dataFetched]);

  useEffect(() => {
    if (!settingData) return;
    setOrgName(settingData?.orgName || "");
    setOrgSlug(settingData?.orgSlug || "");
    setOrgCalLink(settingData?.orgCalLink || "");
    setOrgAbout(settingData?.orgAbout || "");
    setOrgLogo(settingData?.orgLogo || "");
    setOrgBanner(settingData?.orgBanner || "");
    setOrgLogoColor(settingData?.orgLogoColor || "");
    setTeamId(settingData?.teamId || "");

    setOrgTicker(settingData?.orgTicker || "");
    setOrgStock(settingData?.orgStock || "");
    setOrgPublic(settingData?.orgPublic || "no");
    setWidget(settingData?.widget || "");

    setIsEditAccess(
      settingData?.isAccountOwner ||
        settingData?.isOwner ||
        settingData?.isAdmin
        ? true
        : false
    );
    return () => null;
  }, [settingData]);

  const saveSetting = async type => {
    let parma = new FormData();
    parma.append("userId", userData.userId);
    parma.append("org", userData?.org?.id);
    if (type === "orgName") {
      let name = orgName?.trim();
      if (!name) {
        ToastManager.addToast({ title: "Please enter organization name" });
        return false;
      }
      parma.append("orgName", name);
    }
    if (type === "orgCalLink") {
      if (!orgCalLink) {
        ToastManager.addToast({
          title: "Please enter organization calendar link",
        });
        return false;
      }
      parma.append("orgCalLink", orgCalLink);
    }
    if (type === "orgAbout") {
      let about = orgAbout?.trim();
      if (!about) {
        ToastManager.addToast({
          title: "Please enter organization about",
        });
        return false;
      }
      if (about?.length > 1000) {
        ToastManager.addToast({
          title: "Organization About Please use 1000 characters at maximum",
        });
        return false;
      }

      parma.append("orgAbout", about);
    }

    if (type === "orgLogo") {
      if (!orgLogo) {
        ToastManager.addToast({ title: "Please upload organization logo" });
        return false;
      }
      parma.append("orgLogo", orgLogo, orgLogo.name);
    }
    if (type === "orgBanner") {
      if (!orgLogo) {
        ToastManager.addToast({ title: "Please upload organization banner" });
        return false;
      }
      parma.append("orgBanner", orgBanner, orgBanner.name);
    }
    if (type === "orgSlug") {
      parma.append("orgSlug", orgSlug);
    }
    if (type === "orgLogo" || type === "orgLogoColor") {
      parma.append("orgLogoColor", orgLogoColor);
    }

    if (type === "stock") {
      parma.append("orgPublic", orgPublic);
      if (orgStock) {
        parma.append("orgStock", orgStock);
      }
      if (orgTicker) {
        parma.append("orgTicker", orgTicker);
      }
    }

    setLoading(true);
    try {
      const data = await ACCOUNT_SERVICES.updateSetting(parma);
      setLoading(false);
      if (data.code === 200) {
        setDataFetched(false);
        setIsUserReinitialize(new Date());
        if (type === "orgBanner") {
          setSelectBanner(false);
        }
        if (type === "orgLogo") {
          setSelectLogo(false);
        }
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      }
      if (data.message) {
        ToastManager.addToast({ title: data.message });
      }
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
    setLoading(false);
  };

  const onSelectOrgLogo = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setOrgLogo(settingData?.orgLogo || "");
      setSelectLogo(false);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropOrgLogo(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const onSelectOrgBanner = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setOrgBanner(settingData?.orgBanner || "");
      setSelectBanner(false);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropOrgBanner(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };
  useEffect(() => {
    if (!orgLogo?.name) {
      setOrgLogoPreview(orgLogo);
      return;
    }
    const objectUrl = URL.createObjectURL(orgLogo);
    setOrgLogoPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [orgLogo]);

  useEffect(() => {
    if (!orgBanner?.name) {
      setOrgBannerPreview(orgBanner);
      return;
    }
    const objectUrl = URL.createObjectURL(orgBanner);
    setOrgBannerPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [orgBanner]);

  return (
    <div className="events-page-account-setting-content">
      {loading && <FullPageProgress fixed={true} />}
      {isEditAccess || orgName ? (
        <AccountName
          setOrgName={setOrgName}
          orgName={orgName}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
        />
      ) : null}

      {isEditAccess && (
        <AccountSlug
          setOrgSlug={setOrgSlug}
          orgSlug={orgSlug}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
        />
      )}
      {isEditAccess || orgLogoPreview ? (
        <AccountLogo
          selectLogo={selectLogo}
          orgLogoColor={orgLogoColor}
          setOrgLogoColor={setOrgLogoColor}
          onSelectOrgLogo={onSelectOrgLogo}
          orgLogoPreview={orgLogoPreview}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
          settingData={settingData}
          setOrgLogo={setOrgLogo}
          setCropOrgLogo={setCropOrgLogo}
          setSelectLogo={setSelectLogo}
          cropOrgLogo={cropOrgLogo}
        />
      ) : null}
      {isEditAccess || orgBanner ? (
        <AccountBanner
          onSelectOrgBanner={onSelectOrgBanner}
          orgBannerPreview={orgBannerPreview}
          selectBanner={selectBanner}
          cropOrgBanner={cropOrgBanner}
          setCropOrgBanner={setCropOrgBanner}
          setOrgBanner={setOrgBanner}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
          setSelectBanner={setSelectBanner}
        />
      ) : null}
      {isEditAccess || orgAbout ? (
        <AccountAbout
          orgAbout={orgAbout}
          setOrgAbout={setOrgAbout}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
        />
      ) : null}

      {isEditAccess || orgPublic ? (
        <AccountStock
          orgTicker={orgTicker}
          setOrgTicker={setOrgTicker}
          orgStock={orgStock}
          setOrgStock={setOrgStock}
          setOrgPublic={setOrgPublic}
          orgPublic={orgPublic}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
        />
      ) : null}
      {isEditAccess || orgCalLink ? (
        <AccountCalendar
          orgCalLink={orgCalLink}
          setOrgCalLink={setOrgCalLink}
          isEditAccess={isEditAccess}
          saveSetting={saveSetting}
        />
      ) : null}

      {isEditAccess ? (
        <AccountWidget widget={widget} isEditAccess={isEditAccess} />
      ) : null}
      {isEditAccess && (
        <div className="events-page-account-setting-content-row">
          <AccountConnect />
        </div>
      )}
      {teamId && (
        <div className="events-page-account-setting-content-row">
          <AccountTeamID teamId={teamId} />
        </div>
      )}
      {settingData.isAccountOwner ? (
        <AccountResetTeam
          onUpdate={() => {
            setDataFetched(false);
          }}
        />
      ) : settingData ? (
        <AccountLeaveTeam
          onUpdate={() => {
            setDataFetched(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default AccountGeneralSettings;
