import React from "react";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  Twitter,
} from "@mui/icons-material";
import bianca from "../../../assets/images/six/bianca.png";
import daniel from "../../../assets/images/six/daniel.png";

const SixAboutCreated = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-about-section-created">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-about-section-header">
            <div className="events-page-about-section-header-heading text-center events-page-about-section-header-line">
              Created By
            </div>
          </div>

          <div className="events-page-about-section-created-row">
            <div className="events-page-about-section-created-row-frame">
              <img src={daniel} alt="6ix Daniel" />
            </div>
            <div className="events-page-about-section-created-row-content">
              <div className="events-page-about-section-created-row-content-tag">
                CEO of 6ix
              </div>
              <div className="events-page-about-section-created-row-content-heading">
                Daniel Barankin
              </div>
              <div className="events-page-about-section-created-row-content-social">
                <Link
                  to="https://www.linkedin.com/in/daniel-barankin-7396b656/"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Linkedin"
                >
                  <LinkedIn />
                </Link>
                <Link
                  to="https://www.facebook.com/danielbarankin"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Facebook"
                >
                  <Facebook />
                </Link>
                <Link
                  to="https://twitter.com/danielbarankin"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Twitter"
                >
                  <Twitter />
                </Link>
                <Link
                  to="https://www.instagram.com/danielbarankin/"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Instagram"
                >
                  <Instagram />
                </Link>
                <Link
                  to="mailto:daniel@6ix.com"
                  className="events-page-about-section-created-row-content-social-link"
                >
                  <Mail /> daniel@6ix.com
                </Link>
              </div>
              <div className="events-page-about-section-created-row-content-text">
                Daniel Barankin is the founding Chief Executive Officer of 6ix.
              </div>
            </div>
          </div>

          <div className="events-page-about-section-created-row">
            <div className="events-page-about-section-created-row-content">
              <div className="events-page-about-section-created-row-content-tag">
                CRO of 6ix
              </div>
              <div className="events-page-about-section-created-row-content-heading">
                Bianca Pisciola
              </div>
              <div className="events-page-about-section-created-row-content-social">
                <Link
                  to="https://www.linkedin.com/in/bianca-pisciola-54597066"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Linkedin"
                >
                  <LinkedIn />
                </Link>
                <Link
                  to="https://www.facebook.com/bianca.pisciola"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Facebook"
                >
                  <Facebook />
                </Link>
                <Link
                  to="https://twitter.com/biancapisciola"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Twitter"
                >
                  <Twitter />
                </Link>
                <Link
                  to="https://www.instagram.com/biancapisciola/"
                  className="events-page-about-section-created-row-content-social-link"
                  target="_blank"
                  title="Instagram"
                >
                  <Instagram />
                </Link>
                <Link
                  to="mailto:bianca@6ix.com"
                  className="events-page-about-section-created-row-content-social-link"
                >
                  <Mail /> bianca@6ix.com
                </Link>
              </div>
              <div className="events-page-about-section-created-row-content-text">
                Bianca Pisciola is the founding Chief Revenue Officer at 6ix.
              </div>
            </div>
            <div className="events-page-about-section-created-row-frame">
              <img src={bianca} alt="6ix Bianca" />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixAboutCreated;
