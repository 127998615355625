import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import SixSlideSteps from "./SixSlideSteps";
import WhyJoinSix from "./WhyJoinSix";
import WhySixAccess from "./WhySixAccess";
import WhySixAttract from "./WhySixAttract";
import WhySixExpertise from "./WhySixExpertise";
import WhySixInvestment from "./WhySixInvestment";
import "./style.scss";

const WhySix = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleChangeIndex = index => {
    setCurrentStep(index);
  };
  return (
    <React.Fragment>
      <SwipeableViews index={currentStep} onChangeIndex={handleChangeIndex}>
        <div
          className={`event-page-whysix-slide ${
            currentStep === 0 ? "active" : ""
          }`}
        >
          <WhySixAccess />
          <SixSlideSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>

        <div
          className={`event-page-whysix-slide ${
            currentStep === 1 ? "active" : ""
          }`}
        >
          <WhySixInvestment />
          <SixSlideSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
        <div
          className={`event-page-whysix-slide ${
            currentStep === 2 ? "active" : ""
          }`}
        >
          <WhySixAttract />
          <SixSlideSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
        <div
          className={`event-page-whysix-slide ${
            currentStep === 3 ? "active" : ""
          }`}
        >
          <WhySixExpertise />
          <SixSlideSteps
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </div>
      </SwipeableViews>

      <WhyJoinSix />
    </React.Fragment>
  );
};

export default WhySix;
