import React from "react";
import {
  //CircleFill,
  UserIcon,
} from "../../../icons";

const SixSlideSteps = props => {
  const { currentStep } = props;
  return (
    <React.Fragment>
      <div
        className={`event-page-whysix-slide-action ${
          currentStep === 0 ? "event-page-whysix-slide-action-move" : ""
        } `}
      >
        {/* <div className="event-page-whysix-slide-action-dots">
          <div
            className="event-page-whysix-slide-action-dots-item"
            onClick={() => {
              setCurrentStep(0);
            }}
          >
            <CircleFill active={currentStep === 0} />
          </div>
          <div
            className="event-page-whysix-slide-action-dots-item"
            onClick={() => {
              setCurrentStep(1);
            }}
          >
            <CircleFill active={currentStep === 1} />
          </div>
          <div
            className="event-page-whysix-slide-action-dots-item"
            onClick={() => {
              setCurrentStep(2);
            }}
          >
            <CircleFill active={currentStep === 2} />
          </div>
          <div
            className="event-page-whysix-slide-action-dots-item"
            onClick={() => {
              setCurrentStep(3);
            }}
          >
            <CircleFill active={currentStep === 3} />
          </div>
        </div> */}
        <div className="event-page-whysix-slide-action-steps">
          <div className="event-page-whysix-slide-action-steps-item">
            <div className="event-page-whysix-slide-action-steps-item-icon">
              <UserIcon />
            </div>
            <div className="event-page-whysix-slide-action-steps-item-content">
              <div className="event-page-whysix-slide-action-steps-item-content-title">
                Step 1
              </div>
              <div className="event-page-whysix-slide-action-steps-item-content-text">
                Open an Account
              </div>
            </div>
          </div>

          <div className="event-page-whysix-slide-action-steps-item">
            <div className="event-page-whysix-slide-action-steps-item-icon">
              <UserIcon />
            </div>
            <div className="event-page-whysix-slide-action-steps-item-content">
              <div className="event-page-whysix-slide-action-steps-item-content-title">
                Step 2
              </div>
              <div className="event-page-whysix-slide-action-steps-item-content-text">
                Invest, Raise Capital, Share Expertise
              </div>
            </div>
          </div>
          <div className="event-page-whysix-slide-action-steps-item">
            <div className="event-page-whysix-slide-action-steps-item-icon">
              <UserIcon />
            </div>
            <div className="event-page-whysix-slide-action-steps-item-content">
              <div className="event-page-whysix-slide-action-steps-item-content-title">
                Step 3
              </div>
              <div className="event-page-whysix-slide-action-steps-item-content-text">
                Grow Your Network
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SixSlideSteps;
