import React, { useEffect, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Flex } from "@100mslive/roomkit-react";
import { ToastManager } from "../Toast/ToastManager";
import "react-image-crop/src/ReactCrop.scss";

const ImageCropper = props => {
  const { open, onCancel, data, onUpdate, size } = props;
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(data?.ratio);
  const [feature, setFeature] = useState(data?.feature?.[0] || "");
  const imgRef = useRef(null);
  const [minWidth, setMinWidth] = useState(null);
  const [minHeight, setMinHeight] = useState(null);

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: data?.minWidth < mediaWidth ? "%" : "px",
          width: data?.minWidth < mediaWidth ? "90" : data?.minWidth,
          //height: data?.minHeight,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad() {
    if (aspect) {
      const { width, height } = imgRef.current;
      setMinHeight(width < data?.minHeight ? data?.minHeight + "px" : null);
      setMinWidth(width < data?.minWidth ? data?.minWidth + "px" : null);

      const newCrop = convertToPixelCrop(
        centerAspectCrop(width, height, aspect),
        width,
        height
      );

      setCrop(newCrop);
    }
  }
  useEffect(() => {
    if (feature === "freedom") {
      setAspect(undefined);
    } else {
      setAspect(data?.ratio);
    }
  }, [feature, data]);

  useEffect(() => {
    if (aspect && imgRef?.current) {
      const { width, height } = imgRef.current;
      const newCrop = convertToPixelCrop(
        centerAspectCrop(width, height, aspect),
        width,
        height
      );
      setCrop(newCrop);
      setCompletedCrop(newCrop);
    }
  }, [aspect, minWidth]);

  const getCroppedImg = () => {
    const image = imgRef.current;
    const previewCanvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    previewCanvas.width = crop.width;
    previewCanvas.height = crop.height;
    const ctx = previewCanvas.getContext("2d");

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return new Promise((resolve, reject) => {
      previewCanvas.toBlob(blob => {
        if (!blob) {
          reject({ message: "Image crop is empty" });
          return;
        }
        blob["name"] = "image.png";
        resolve(blob);
      }, "image/png");
    });
  };
  const onSave = async () => {
    try {
      const croppedImage = await getCroppedImg();
      //formData.append("file", croppedImage, "croppedImage.jpeg");
      // console.log(URL.createObjectURL(croppedImage), "croppedImage");
      onUpdate(croppedImage);
    } catch (err) {
      ToastManager.addToast({ title: err.message });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog "
      maxWidth={size || "sm"}
      fullWidth={true}
    >
      {data?.title && (
        <DialogTitle className="text-center">{data?.title}</DialogTitle>
      )}
      <DialogContent className="text-center">
        <ReactCrop
          className="eventsCrop-box"
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
          aspect={aspect}
          minHeight={data?.minHeight}
          minWidth={data?.minWidth}
          circularCrop={feature === "circle"}
          keepSelection
          style={{
            minWidth: minWidth,
            minHeight: minHeight,
          }}
        >
          <img
            className="eventsCrop-box-img"
            src={data?.image}
            alt=""
            onLoad={onImageLoad}
            ref={imgRef}
            style={{
              minWidth: minWidth,
            }}
          />
        </ReactCrop>
      </DialogContent>
      <Flex className="eventsCrop-actions">
        {data?.feature?.map((item, index) => (
          <Button
            key={index}
            size="medium"
            className={`eventsCrop-actions-btn ${
              feature === item ? "active" : ""
            } `}
            variant="outlined"
            onClick={() => setFeature(item)}
          >
            {item}
          </Button>
        ))}
      </Flex>
      <DialogActions className="custom-dialog-action mt-10">
        <Button
          onClick={onCancel}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
        >
          Crop
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageCropper;
