import React from "react";
import SEO from "../components/Common/SEO";
import EventFooter from "../components/Event/EventFooter";
import LandingTouch from "../components/Landing/LandingTouch";
import PageHeader from "../components/PageHeader";

const SupportPage = () => {
  return (
    <React.Fragment>
      <SEO title="Support | 6ix Events" />
      <div className="events-page-support events6ix-layout-page">
        <PageHeader type="overview" />
        <div className="events6ix-layout-page-content">
          <LandingTouch />
        </div>
      </div>
      <EventFooter />
    </React.Fragment>
  );
};

export default SupportPage;
