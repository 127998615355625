import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import frame7 from "../../../assets/images/six/frame7.png";
import frame8 from "../../../assets/images/six/frame8.png";
import frame9 from "../../../assets/images/six/frame9.png";
import frame10 from "../../../assets/images/six/frame10.png";

const WhySixAttract = () => {
  return (
    <React.Fragment>
      <div className="event-page-whysix-slide-investment">
        <div className="event-page-whysix-slide-investment-top">
          <div className="event-page-whysix-slide-investment-heading">
            Attract more capital with less stress and more success
          </div>
          <div className="event-page-whysix-slide-investment-content">
            <div className="event-page-whysix-slide-investment-content-text">
              6ix is the only networked investor relations solution designed to
              help you reach investors, get coverage and attract capital.
            </div>
            <div className="event-page-whysix-slide-investment-content-buttons">
              <Button
                component={Link}
                to="/create"
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
              >
                GET STARTED
              </Button>
            </div>
          </div>
        </div>
        <div className="event-page-whysix-slide-investment-bottom">
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame7} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame8} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame9} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame10} alt="frame" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhySixAttract;
