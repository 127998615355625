import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import frame1 from "../../../assets/images/six/frame1-2.png";
import frame2 from "../../../assets/images/six/frame2-2.png";

const WhySixAccess = () => {
  return (
    <React.Fragment>
      <div className="event-page-whysix-slide-access">
        <div className="event-page-whysix-slide-access-frame">
          <img src={frame1} alt="frame" />
        </div>
        <div className="event-page-whysix-slide-access-content">
          <div className="event-page-whysix-slide-access-content-top">
            believe in capitalism again
          </div>
          <div className="event-page-whysix-slide-access-content-heading">
            6ix is your all-access pass to the investment world!
          </div>
          <div className="event-page-whysix-slide-access-content-bottom">
            Invest, attract capital and share expertise — all from one digital
            stage.
          </div>
          <div className="event-page-whysix-slide-access-content-text">
            Whether you’re building your investment portfolio,
            <br />
            raising a fresh round of capital or aspiring to share your
            knowledge,
            <br /> 6ix is your all-access pass to the investment world!
          </div>
          <div className="event-page-whysix-slide-access-content-buttons">
            <Button
              component={Link}
              to="/create"
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
            >
              GET STARTED
            </Button>
          </div>
        </div>
        <div className="event-page-whysix-slide-access-frame">
          <img src={frame2} alt="frame" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhySixAccess;
