import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import frame3 from "../../../assets/images/six/frame3.png";
import frame4 from "../../../assets/images/six/frame4.png";
import frame5 from "../../../assets/images/six/frame5.png";
import frame6 from "../../../assets/images/six/frame6.png";

const WhySixInvestment = () => {
  return (
    <React.Fragment>
      <div className="event-page-whysix-slide-investment">
        <div className="event-page-whysix-slide-investment-top">
          <div className="event-page-whysix-slide-investment-heading">
            Invest smarter with access to other investors, CEOs & experts
          </div>
          <div className="event-page-whysix-slide-investment-content">
            <div className="event-page-whysix-slide-investment-content-text">
              6ix connects you with innovative companies and provides the tools
              you need to make informed investment decisions.
            </div>
            <div className="event-page-whysix-slide-investment-content-buttons">
              <Button
                component={Link}
                to="/create"
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
              >
                GET STARTED
              </Button>
            </div>
          </div>
        </div>
        <div className="event-page-whysix-slide-investment-bottom">
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame3} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame4} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame5} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame6} alt="frame" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhySixInvestment;
