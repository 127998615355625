import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import frame11 from "../../../assets/images/six/frame11.png";
import frame12 from "../../../assets/images/six/frame12.png";
import frame13 from "../../../assets/images/six/frame13.png";
import frame14 from "../../../assets/images/six/frame14.png";

const WhySixExpertise = () => {
  return (
    <React.Fragment>
      <div className="event-page-whysix-slide-investment">
        <div className="event-page-whysix-slide-investment-top">
          <div className="event-page-whysix-slide-investment-heading">
            Share your expertise and build your brand
          </div>
          <div className="event-page-whysix-slide-investment-content">
            <div className="event-page-whysix-slide-investment-content-text">
              6ix provides a global stage for you to showcase your knowledge,
              attract followers, and monetize your insights.
            </div>
            <div className="event-page-whysix-slide-investment-content-buttons">
              <Button
                component={Link}
                to="/create"
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
              >
                GET STARTED
              </Button>
            </div>
          </div>
        </div>
        <div className="event-page-whysix-slide-investment-bottom">
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame11} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame12} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame13} alt="frame" />
          </div>
          <div className="event-page-whysix-slide-investment-bottom-item">
            <img src={frame14} alt="frame" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhySixExpertise;
