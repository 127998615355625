import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title = "",
  description = "",
  type = "",
  name = "",
  image = "",
  url = "",
}) => {
  return (
    <Helmet>
      <title>{title ? title : "6ix Events"}</title>
      <meta
        name="description"
        content={
          description ? description : "Livestream to millions of investors"
        }
      />
      <link rel="canonical" href={url ? url : "https://6ix.com"} />
      <meta property="og:title" content={title ? title : "6ix Events"} />
      <meta
        property="og:description"
        content={
          description ? description : "Livestream to millions of investors"
        }
      />
      <meta property="og:url" content={url ? url : "https://6ix.com"} />
      <meta
        property="og:image"
        content={image ? image : "https://6ix.com/images/social-banner.jpg"}
      />
      <meta property="og:type" content={type ? type : "website"} />
      <meta property="og:site_name" content="6ix Events" />
      <meta property="og:locale" content="en_IN" />

      <meta name="twitter:creator" content={name ? name : "@6ix"} />
      <meta property="twitter:domain" content="6ix.com" />
      <meta name="twitter:site" content="@6ix" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || "6ix Events"} />
      <meta
        name="twitter:description"
        content={
          description ? description : "Livestream to millions of investors"
        }
      />
      <meta
        name="twitter:image"
        content={image ? image : "https://6ix.com/images/social-banner.jpg"}
      />
    </Helmet>
  );
};

export default SEO;
