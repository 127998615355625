import React from "react";
import PricingContent from "./PricingContent";
import "./style.scss";
const SixPricing = () => {
  return (
    <section className="event-page-pricing-section">
      <div className="events6ix-layout-page-middle">
        <div className="event-page-pricing-section-header">
          <h1 className="event-page-pricing-section-header-heading">Pricing</h1>
          <div className="event-page-pricing-section-header-text">
            6ix builds modular capital markets software that helps you
            <br />
            <span className="text-primary">
              invest capital, attract capital{" "}
              <span className="text-white">and</span> share expertise.
            </span>
          </div>
        </div>
        <PricingContent />
      </div>
    </section>
  );
};

export default SixPricing;
